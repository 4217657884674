export const text = [
  `We hope you had an incredible time at Gartner Winners Circle 2023!`,
  `You can find all your photos and highlights videos from your event on the Winners Circle website.`,
  `We look forward to seeing you in 2025!`,
  ``,
  ``,
  ``
];

export const test1 = [
  ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti expedita ',
  ' blanditiis architecto aliquam placeat labore magni recusandae consequatur',
  'neque! Rerum similique',
  ' quisquam saepe app praesentium molestiae officiis, ullam quaerat modi!',
  ' Lorem ipsum appledolor sit amet consectetur adipisicing elit. Corrupti expedita ',
  ' blanditiis architecto aliquam placeat labore magni recusandae consequatur',
  'neque! Rerum similique',
  ' quisquam saepe app praesentium molestiae officiis, ullam quaerat modi!',
];

export const test2= [
  ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti expedita ',
  ' blanditiis architecto aliquam placeat labore magni recusandae consequatur',
  'neque! Rerum similique',
  ' quisquam saepe app praesentium molestiae officiis, ullam quaerat modi!',
  ' Lorem ipsum appledolor sit amet consectetur adipisicing elit. Corrupti expedita ',
  ' blanditiis architecto aliquam placeat labore magni recusandae consequatur',
  'neque! Rerum similique',
  ' quisquam saepe app praesentium molestiae officiis, ullam quaerat modi!',
];
