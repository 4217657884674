import type {
  setItemAsync as expoSetItemAsync,
  getItemAsync as expoGetItemAsync,
} from 'expo-secure-store';

const state: Record<string, any> = {};

const setItemAsync: typeof expoSetItemAsync = async (key, value) => {
  state.key = value;

  if (
    window.localStorage &&
    typeof window.localStorage.setItem === 'function'
  ) {
    window.localStorage.setItem(key, value);
  }
};

const getItemAsync: typeof expoGetItemAsync = async (key) => {
  if (
    window.localStorage &&
    typeof window.localStorage.getItem === 'function'
  ) {
    return window.localStorage.getItem(key) ?? null;
  } else {
    return state[key] ?? null;
  }
};

export const SecureStorage = {
  setItemAsync,
  getItemAsync,
};
