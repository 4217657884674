import { useEffect, useState } from 'react';
import * as Font from 'expo-font';
import { myTheme } from '../theme';

const useApp = () => {
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    async function loadFont() {
      await Font.loadAsync({
        coreSansRegular: require('../../assets/fonts/s-core_-_coresanscr-45regular-webfont.ttf'),
        coreSansBold: require('../../assets/fonts/s-core_-_coresanscr-65bold-webfont.ttf'),
        coreSansExtraBold: require('../../assets/fonts/s-core_-_coresanscr-75extrabold-webfont.ttf'),
        coreSansMedium: require('../../assets/fonts/s-core_-_coresanscr-55medium-webfont.ttf'),
      });
      setFontLoaded(true);
    }
    loadFont();
  }, []);

  const theme = {
    ...myTheme,
  };

  const [isFahrenheit, setIsFahrenheit] = useState(() => {
    const storedValue = window.localStorage.getItem('units');
    if (storedValue !== null) {
      return JSON.parse(storedValue);
    } else {
      return true;
    }
  });
  const handleIsFahrenheit = () => {
    setIsFahrenheit((prev: any) => !prev),
      window.localStorage.setItem('units', JSON.stringify(isFahrenheit));
  };
  useEffect(() => {
    window.localStorage.setItem('units', JSON.stringify(isFahrenheit));
  }, [isFahrenheit]);
  return { theme, fontLoaded, isFahrenheit, handleIsFahrenheit };
};

export default useApp;
