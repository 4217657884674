export const colourConst = {
  blue: '#035CA7',
  green: '#158D2B',
  ambar: '#dcc609',
  event: '#FCC602',
  inactiveGray: '#AFAFAF',
  white: '#FFFFFF',
  font: '#575756',
  fontOpacity: 'rgba(87, 87, 86, 0.2)',
  transparent: 'rgba(0, 0, 0, 0)',
  background: '#EBF6FF',
  whiteTransparent: 'rgba(255, 255, 255, 0.8)',
  unselected: '#58595B',
};

export const imgIconSize = {
  width: 30,
  height: 30,
};

export const fontSize = {
  extraSmall: 9,
  small: 10,
  smallRegular: 11,
  regular: 12,
  smallMedium: 14,
  medium: 15,
  mediumLarge: 18,
  large: 20,
  extraLarge: 24,
  xxL: 30,
};

export const fontFamilyConst = {
  regular: 'coreSansRegular',
  medium: 'coreSansMedium',
  bold: 'coreSansBold',
  extraBold: 'coreSansExtraBold',
};
export const layoutAgenda = {
  radius: 10,
  radiusTab: 5,
  margin: 16,
  marginBottom: 15,
  gap: 2,
  paddingTabs: 10,
};
export const timeslots = {
  margin: 16,
  marginBottom: 9,
  radius: 10,
  padding: 17,
  timeMargin: 8,
  textMargin: 5,
};
export const homeLayout = {
  maxWidth: 500,
};
export const shadowConst = {
  radius: 5,
  opacity: 0.25,
  elevation: 10,
  width: 0,
  height: 10,
};
