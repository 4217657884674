interface useTextClosedSiteProps {
  (prgh: string, linkWord: string): { head: string; tail: string };
}

const useTextClosedSite: useTextClosedSiteProps = (
  prgh,
  linkWord
) => {
  const startIndex = prgh.indexOf(linkWord);
  const endIndex = startIndex + linkWord.length - 1;

  const head = prgh.slice(0, startIndex);
  const tail = prgh.slice(endIndex + 1);

  return { head, tail };
};

export default useTextClosedSite;
