/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useQuery, QueryClient } from 'react-query';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { createAsyncStoragePersistor } from 'react-query/createAsyncStoragePersistor-experimental';
import { useSecureContext } from './context';

const asyncStoragePersistor = createAsyncStoragePersistor({
  storage: AsyncStorage,
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 15, // 15 minutes
    },
  },
});

persistQueryClient({
  queryClient,
  persistor: asyncStoragePersistor,
});

export const useQueryWithToken = <T extends any>(
  queryKey: string,
  queryFunction: (token: string) => Promise<T>
) => {
  const { initialized, token, setState } = useSecureContext();
  const { locationAndWave } = useSecureContext();
  return useQuery<T | null>(
    `${queryKey} ${locationAndWave?.wave?.ProjectId}`,
    () => {
      if (!initialized || !token) {
        return null;
      }

      return queryFunction(token, locationAndWave).catch((error) => {
        if (
          !!error &&
          typeof error === 'object' &&
          'status' in error &&
          error.status === 401
        ) {
          setState((prev) => ({
            ...prev,
            token: null,
            state: null,
          }));
        }

        throw error;
      });
    }
  );
};
