import { StyleSheet, View, Image } from 'react-native';
import React from 'react';
import TextClosedSite from '../components/TextClosedSite/index';
import {text as message} from '../utils/text';

const ClosedSite = () => {
  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        source={require('../../assets/gwc23_spectrum_logo-colour-no_date.png')}
        resizeMode="contain"
      />
      <TextClosedSite
      text={message} 
      linkWord='Winners Circle website.'
      url='https://www.gartnerwinnerscircle.com/'
      />
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    width: '90%',
    height: '40%',
    marginBottom: 50,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 40,
    alignContent: 'center',
  },
});

export default ClosedSite;
