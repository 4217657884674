import React, {
  createContext,
  useState,
  useMemo,
  useContext,
  ReactNode,
} from 'react';

export interface NotificationsState {
  unreadNotifications: number;
  setUnreadNotifications: (count: number) => void;
  refetchNotifications: () => void;
  setRefetchNotifications: (fn: () => void) => void;
}

const NotificationsContext = createContext<NotificationsState>({
  unreadNotifications: 0,
  setUnreadNotifications: () => {
    throw new Error(
      'NotificationsContext was accessed outside of a NotificationsContextProvider'
    );
  },
  refetchNotifications: () => {
    throw new Error(
      'NotificationsContext was accessed outside of a NotificationsContextProvider'
    );
  },
  setRefetchNotifications: () => {
    throw new Error(
      'NotificationsContext was accessed outside of a NotificationsContextProvider'
    );
  },
});

export const NotificationsContextProvider = ({
  children,
}: {
  children: ReactNode | readonly ReactNode[];
}) => {
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [refetchNotifications, setRefetchNotifications] = useState<() => void>(
    () => () => undefined
  );

  const value = useMemo(
    () => ({
      unreadNotifications,
      setUnreadNotifications,
      refetchNotifications,
      setRefetchNotifications,
    }),
    [unreadNotifications, refetchNotifications]
  );

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

// Custom hook to use NotificationsContext
export const useNotificationsContext = () => useContext(NotificationsContext);
