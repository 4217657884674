import { StyleSheet, Text, Pressable } from 'react-native';
import {
  colourConst as colour,
  fontSize as size,
} from '../../globalStyles';
import * as Linking from 'expo-linking';
import React from 'react';

interface TextWithLinkProps {
  head: string;
  tail: string;
  url: string;
  linkWord: string;
}

const TextWithLink: React.FC<TextWithLinkProps> = ({
  head,
  tail,
  linkWord,
  url,
}) => {
  return (
    <Text style={style.linkContainer}>
      <Text style={style.text}>{head}</Text>
      <Pressable
        testID="link"
        onPress={() => Linking.openURL(url)}
        style={({ pressed }) => [
          {
            backgroundColor: pressed ? 'rgb(210, 230, 255)' : 'white',
          },
        ]}
      >
        <Text style={style.link}>{linkWord}</Text>
      </Pressable>
      <Text style={style.text}>{tail}</Text>
    </Text>
  );
};

const style = StyleSheet.create({
  container: {
    paddingHorizontal: 10,
  },
  link: {
    color: colour.blue,
    fontSize: size.extraLarge,
  },
  linkContainer: {
    textAlign: 'center',
    marginTop: 10,
  },
  text: {
    color: colour.font,
    fontSize: size.extraLarge,
    textAlign: 'center',
    marginTop: 10,
  },
});

export default TextWithLink;
