import { Text, StyleSheet, View } from 'react-native';
import React from 'react';
import {
  colourConst as colour,
  fontSize as size,
} from '../../globalStyles';
import { TextClosedSiteProps } from '../../utils/types';
import useTextClosedSite from './useTextClosedSite';
import TextWithLink from '../TextWithLink';

const TextClosedSite: React.FC<TextClosedSiteProps> = ({
  linkWord,
  url,
  text,
}) => {
  return (
    <View testID="viewWithLink" style={style.container}>
      {linkWord && url
        ? text.map((prgh) => {
            if (prgh.includes(linkWord)) {
              const { head, tail } = useTextClosedSite(
                prgh,
                linkWord
              );

              return (
                <TextWithLink
                  key={prgh}
                  head={head}
                  tail={tail}
                  linkWord={linkWord}
                  url={url}
                />
              );
            } else {
              return (
                <Text style={style.text} key={prgh}>
                  {prgh}
                </Text>
              );
            }
          })
        : text.map((prgh) => (
            <Text key={prgh} style={style.text}>
              {prgh}
            </Text>
          ))}
    </View>
  );
};

const style = StyleSheet.create({
  container: {
    paddingHorizontal: 10,
  },
  text: {
    color: colour.font,
    fontSize: size.extraLarge,
    textAlign: 'center',
    marginTop: 10,
  },
});

export default TextClosedSite;
